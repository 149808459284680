import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const AddChildCategory = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const [data, setData] = useState({
        name: editData?.name || '',
        sortOrder: editData?.sortOrder || '',
        parentCategory: editData?.parentCategory || location.state?.state?._id,
        metaTitle: editData?.metaTitle || '',
        metaKeyword: editData?.metaKeyword || '',
        metaDescription: editData?.metaDescription || '',
        isActive: editData?.isActive || false,
    });

    const [errors, setErrors] = useState({
        name: '',
        sortOrder: '',
        metaTitle: '',
        metaKeyword: '',
        metaDescription: '',
    });

    const handleChange = (e) => {
        const { name, checked, value } = e.target;
        if (name === "isActive") {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.name.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Name is required'
            }));
            hasErrors = true;
        }

        if (!data.sortOrder.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                sortOrder: 'Sort order is required'
            }));
            hasErrors = true;
        }

        if (!data.metaTitle.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaTitle: 'Meta title is required'
            }));
            hasErrors = true;
        }
        if (!data.metaKeyword.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaKeyword: 'Meta keyword is required'
            }));
            hasErrors = true;
        }
        if (!data.metaDescription.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                metaDescription: 'Meta description is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            let requestData = { ...data };
            if (requestData.parentCategory) {
            } else {
                delete requestData.parentCategory;
            }

            const response = await axiosInstance.post(`category/createCategory`, requestData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/category");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }

    };
    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            let requestData = { ...data };
            if (requestData.parentCategory) {
            } else {
                delete requestData.parentCategory;
            }
            const response = await axiosInstance.put(`category/updateCategory/${editData?._id}`, requestData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/category");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title">Add Child Category </h3>
                </div>
                <div className="row grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Parent Category
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="parentCategory"
                                                name="parentCategory"
                                                placeholder="Enter name"
                                                defaultValue={location.state?.state?.name}
                                                readOnly
                                            />
                                            {errors.parentCategory && <p className="text-danger">{errors.parentCategory}</p>}
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Name
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="name"
                                                name="name"
                                                placeholder="Enter name"
                                                value={data.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <p className="text-danger">{errors.name}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Sort Order
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="sortOrder"
                                                name="sortOrder"
                                                placeholder="Enter sort order"
                                                value={data.sortOrder}
                                                onChange={handleChange}
                                            />
                                            {errors.sortOrder && <p className="text-danger">{errors.sortOrder}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                            {errors.metaTitle && <p className="text-danger">{errors.metaTitle}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Keyword
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                            {errors.metaKeyword && <p className="text-danger">{errors.metaKeyword}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                            {errors.metaDescription && <p className="text-danger">{errors.metaDescription}</p>}
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 "
                                        >Is Active
                                        </label>
                                        <div className="col-sm-9">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox" className="custom-control-input"
                                                    name='isActive'
                                                    id="isActiveSwitch"
                                                    checked={data.isActive}
                                                    onChange={(e) => handleChange(e)} />
                                                <label className="custom-control-label" htmlFor="isActiveSwitch">{data.isActive === true ? "Active" : "Deactive"}</label>

                                            </div>
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label"></label>
                                                <div className="col-sm-9">

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/category"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">
                            {Array.isArray(data.file) && (
                                <div className="border w-100 h-80 text-center mb-4">
                                    <h6 className="mt-3">Upload Brochure Preview</h6>
                                    {data.file.map((file, index) => (
                                        <div key={index} className="d-inline-block m-3">
                                            <Link
                                                to={file instanceof File ? URL.createObjectURL(file) : ImageLink(file)}
                                                target="_blank"
                                            >
                                                <img
                                                    src="images/icon/pdf.png"
                                                    alt="pdf_preview"
                                                    className="ms-3 me-3 mb-3"
                                                    width={60}
                                                />
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default AddChildCategory