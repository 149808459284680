import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'

const Setting = () => {
    const [settingData, setSettingData] = useState([]);
    const [data, setData] = useState({
        websiteMetaTitle: '',
        websiteMetaDescription: '',
        headOffice: '',
        contactEmail: '',
        contactNumber: '',
        whatsAppNumber: '',
        officeMapUrl: '',
        logo: '',
        notificationBanner: '',
        favicon: '',
        testimonialImg: '',
        headerLayout: '',
        backgroundImg: '',
        collectionTitle: '',
        collectionSubTitle: '',
        brandTitle: '',
        brandSubTitle: '',
        testimonialTitle: '',
        testimonialSubTitle: '',
        instagramTitle: '',
        branchTitle: '',
        clientTitle: '',
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`setting/getSettings`);
            const { data } = response.data;
            setSettingData(data);
            setData({ ...data });
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleChange = (e) => {
        const { name, files, value } = e.target;
        if (name === 'logo' || name === 'favicon' || name === 'testimonialImg' || name === 'backgroundImg' || name === "notificationBanner") {
            setData({ ...data, [name]: files.length > 0 ? files[0] : '' });
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else {
            setData({ ...data, [name]: value });
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (data.logo instanceof File) {
                formData.append("logo", data.logo);
            }
            if (data.notificationBanner instanceof File) {
                formData.append("notificationBanner", data.notificationBanner);
            }
            if (data.favicon instanceof File) {
                formData.append("favicon", data.favicon);
            }
            formData.append("websiteMetaTitle", data.websiteMetaTitle);
            formData.append("websiteMetaDescription", data.websiteMetaDescription);
            formData.append("headOffice", data.headOffice);
            formData.append("contactEmail", data.contactEmail);
            formData.append("contactNumber", data.contactNumber);
            formData.append("whatsAppNumber", data.whatsAppNumber);
            formData.append("officeMapUrl", data.officeMapUrl);
            formData.append("headerLayout", data.headerLayout);
            formData.append("collectionTitle", data.collectionTitle);
            formData.append("collectionSubTitle", data.collectionSubTitle);
            formData.append("brandTitle", data.brandTitle);
            formData.append("brandSubTitle", data.brandSubTitle);
            formData.append("testimonialTitle", data.testimonialTitle);
            formData.append("testimonialSubTitle", data.testimonialSubTitle);
            formData.append("instagramTitle", data.instagramTitle);
            formData.append("branchTitle", data.branchTitle);
            formData.append("clientTitle", data.clientTitle);
            if (data.testimonialImg instanceof File) {
                formData.append("testimonialImg", data.testimonialImg);
            }
            if (data.backgroundImg instanceof File) {
                formData.append("backgroundImg", data.backgroundImg);
            }
            const response = await axiosInstance.put(`setting/updateSettings`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {

            toast.error('Something went wrong. Please try again!!');
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Settings </h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-8  ">
                        <div className="card">
                            <div className="card-body">
                                <form className="pt-3" onSubmit={handleUpdate}>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Website Meta Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="websiteMetaTitle"
                                                name="websiteMetaTitle"
                                                placeholder="Enter website meta title"
                                                value={data.websiteMetaTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Website Meta Description
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="websiteMetaDescription"
                                                name="websiteMetaDescription"
                                                placeholder="Enter website meta description"
                                                value={data.websiteMetaDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Notification Banner
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="notificationBanner"
                                                name="notificationBanner"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Logo
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="logo"
                                                name="logo"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Favicon
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="favicon"
                                                name="favicon"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Head Office
                                        </label>
                                        <div className="col-sm-9">
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="headOffice"
                                                name="headOffice"
                                                placeholder="Enter headOffice"
                                                value={data.headOffice}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Contact Email
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="contactEmail"
                                                name="contactEmail"
                                                placeholder="Enter contactEmail"
                                                value={data.contactEmail}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Contact Number
                                        </label>
                                        <div className="col-sm-9">
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="contactNumber"
                                                name="contactNumber"
                                                placeholder="Enter contactNumber"
                                                value={data.contactNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            WhatsApp Number
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="number"
                                                className="form-control form-control-sm"
                                                id="whatsAppNumber"
                                                name="whatsAppNumber"
                                                placeholder="Enter whatsAppNumber"
                                                value={data.whatsAppNumber}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Office Map Url
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="officeMapUrl"
                                                name="officeMapUrl"
                                                placeholder="Enter office map url"
                                                value={data.officeMapUrl}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Header Layout
                                        </label>
                                        <div className="col-sm-9">
                                            <select
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="headerLayout"
                                                name="headerLayout"
                                                onChange={handleChange}
                                                value={data.headerLayout}
                                            >
                                                <option value='1'>Header 1</option>
                                                <option value='2'>Header 2</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Testimonial Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="testimonialImg"
                                                name="testimonialImg"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >
                                            Background Img
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="backgroundImg"
                                                name="backgroundImg"
                                                placeholder="Enter background img"
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Collection Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="collectionTitle"
                                                name="collectionTitle"
                                                placeholder="Enter collection title"
                                                value={data.collectionTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Collection Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="collectionSubTitle"
                                                name="collectionSubTitle"
                                                placeholder="Enter collection sub title"
                                                value={data.collectionSubTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Brand Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="brandTitle"
                                                name="brandTitle"
                                                placeholder="Enter brand title"
                                                value={data.brandTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Brand Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="brandSubTitle"
                                                name="brandSubTitle"
                                                placeholder="Enter brand sub title"
                                                value={data.brandSubTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Testimonial Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="testimonialTitle"
                                                name="testimonialTitle"
                                                placeholder="Enter testimonial title"
                                                value={data.testimonialTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Testimonial Sub Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="testimonialSubTitle"
                                                name="testimonialSubTitle"
                                                placeholder="Enter testimonial sub title"
                                                value={data.testimonialSubTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Instagram Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="instagramTitle"
                                                name="instagramTitle"
                                                placeholder="Enter instagram title"
                                                value={data.instagramTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Branch Title
                                        </label>
                                        <div className="col-sm-9">
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="branchTitle"
                                                name="branchTitle"
                                                placeholder="Enter branch title"
                                                value={data.branchTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                    <div className="form-group row">
                                        <label
                                            htmlFor="exampleInputUsername2"
                                            className="col-sm-3 col-form-label"
                                        >Client Title
                                        </label>
                                        <div className="col-sm-9">
                                            <textarea
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="clientTitle"
                                                name="clientTitle"
                                                placeholder="Enter client title"
                                                value={data.clientTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>


                                    <div className='text-center'>
                                        <Link to="/slider"><button className="btn btn-secondary mr-2">
                                            Back </button></Link>
                                        <button type="submit" className="btn btn-success">
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 stretch-card">
                        <div className="card">

                            <div className="border w-100 h-500 text-center">
                                <h6 className="mt-3">Notification Banner Preview</h6>
                                {data.notificationBanner instanceof File ? (
                                    <img src={URL.createObjectURL(data.notificationBanner)} alt="notificationBanner" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(settingData.notificationBanner)} alt="notificationBanner" className="w-50 mb-3" />
                                )}
                            </div>

                            <div className="border w-100 h-500 text-center">
                                <h6 className="mt-3">Logo Preview</h6>
                                {data.logo instanceof File ? (
                                    <img src={URL.createObjectURL(data.logo)} alt="logo_preview" className="w-50 mb-3" />
                                ) : (
                                    <img src={ImageLink(settingData.logo)} alt="logo_preview" className="w-50 mb-3" />
                                )}
                            </div>

                            {data.favicon &&
                                <div className="border w-100 h-80 text-center ">
                                    <h6 className="mt-3">Favicon Preview</h6>
                                    {data.favicon instanceof File ? (
                                        <img src={URL.createObjectURL(data.favicon)} alt="favicon_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(settingData.favicon)} alt="favicon_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }

                            {data.testimonialImg &&
                                <div className="border w-100 h-80 text-center ">
                                    <h6 className="mt-3">Testimonial Img Preview</h6>
                                    {data.testimonialImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.testimonialImg)} alt="favicon_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(settingData.testimonialImg)} alt="favicon_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }

                            {data.backgroundImg &&
                                <div className="border w-100 h-80 text-center ">
                                    <h6 className="mt-3">Background Img Preview</h6>
                                    {data.backgroundImg instanceof File ? (
                                        <img src={URL.createObjectURL(data.backgroundImg)} alt="favicon_preview" className="w-50 mb-3" />
                                    ) : (
                                        <img src={ImageLink(settingData.backgroundImg)} alt="favicon_preview" className="w-50 mb-3" />
                                    )}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Setting;